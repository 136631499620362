<template>

    <div :id="'scrollto-' + name.toLowerCase()" :class="'image--block pos-' + position + ' bg-' + background + (slider ? ' slider--block' : '')">
        <div class="image--block-content col-lg-6 col-md-4">
            <div class="slider" data-arrows="true" data-paging="false" :data-timing="timing" v-if="slider">
                <span class="swipe" v-if="swipeExplain">
                    <img src="@/assets/img/icons/swipe.svg" alt="">
                </span>
                <ul class="slides">
                    <li class="col-lg-12" v-for="(image, index) in images" :key="index">
                        <background-image 
                            :image="Array.isArray(image) ? image[0] : image" 
                            :prefix="prefix" 
                            :position="Array.isArray(image) ? image[1] : ''">
                        </background-image>
                    </li>
                </ul>
            </div>
            <background-image :image="images[0]" :prefix="prefix" v-else></background-image>
        </div>
        <div class="container">
            <div class="row section">
                <div class="col-lg-5 col-md-7" :class="{ 'col-lg-offset-7 col-md-offset-5': position == 'left' }">
                    
                    <slot></slot>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        name: String,
        position: String,
        background: String,
        prefix: String,
        images: Array,
        timing: Number
    },
    computed: {
        slider() {
            return this.images.length > 1;
        },
        swipeExplain() {
            return window.innerWidth < 768 && this.tutorial;
        }
    }
}
</script>
