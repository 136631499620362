<template>
    
    <page page="accomodatie" theme="light" type="page">
        
        <div class="action--bar">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <a href="#scrollto-binnenkomst" data-position="1">
                            <div class="icon"><img src=@/assets/img/icons/home.svg></div>
                            <span>Binnenkomst</span>
                        </a>
                        <a href="#scrollto-keuken" data-position="2">
                            <div class="icon"><img src=@/assets/img/icons/kitchen.svg></div>
                            <span>Keuken</span>
                        </a>
                        <a href="#scrollto-slaapkamer" data-position="3">
                            <div class="icon"><img src=@/assets/img/icons/bed.svg></div>
                            <span>Slaapkamer</span>
                        </a>
                        <a href="#scrollto-badkamer" data-position="4">
                            <div class="icon"><img src=@/assets/img/icons/shower.svg></div>
                            <span>Badkamer</span>
                        </a>
                        <a href="#scrollto-kinderen" data-position="5">
                            <div class="icon"><img src=@/assets/img/icons/child.svg></div>
                            <span>Kinderen</span>
                        </a>
                        <a href="#scrollto-binnenplaats" data-position="6">
                            <div class="icon"><img src=@/assets/img/icons/tree-alt.svg></div>
                            <span>Binnenplaats</span>
                        </a>
                        <a href="#scrollto-huisdieren" data-position="7">
                            <div class="icon"><img src=@/assets/img/icons/paw.svg></div>
                            <span>Huisdieren</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <slider-block 
            :name="tab.name"
            :position="tab.position" 
            background="default"
            :images="tab.images" 
            :timing="3000" 
            prefix="accomodatie/small/"
            v-for="(tab, index) in tabs"
            :key="index">

            <h3>{{ tab.name }}</h3>
            <div v-html="tab.content"></div>
        </slider-block>

        <div id="cta--block" class="section">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-md-offset-2">
                        <h2>Omgeving &amp; Arrangementen</h2>
                        <p>
                            We hebben in de omgeving een prachtige natuur en veel activiteiten die u tijdens uw verblijf kunt doen. Neem eens een kijkje!
                        </p>
                        <div class="btn--bar">
                            <span><router-link to="/omgeving" class="btn btn-secondary btn-icon"><img src="@/assets/img/icons/tree-alt.svg" />Bekijk omgeving</router-link></span>
                            <span><router-link to="/arrangementen" class="btn btn-secondary btn-icon"><img src="@/assets/img/icons/users.svg" />Onze arrangementen</router-link></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </page>

</template>

<script>
import Page from '../modules/AppPage.vue';
import SliderBlock from '../modules/AppSliderBlock.vue';

export default {
    metaInfo: {
        title: 'Accomodatie',
        meta: [
            { vmid: 'description', name: 'description', content: 'Bij B&B de Oude Stoffeerderij komt u binnen via openslaande tuindeuren in een knusse, sfeervolle open ruimte met links een zitgedeelte bij de kachel met een 3-zits designbank, een armstoel en voetenbanken.' }
        ],
        link: [
            { rel: 'canonical', href: 'https://www.deoudestoffeerderij.nl/accomodatie'}
        ]
    },
    data() {
        return {
            tabs: [
                {
                    name: 'Binnenkomst',
                    content: `
                    <p>
                        Bij B&amp;B de Oude Stoffeerderij komt u binnen via openslaande tuindeuren in een knusse,
                        sfeervolle open ruimte met links een zitgedeelte bij de kachel met een 3-zits designbank, een
                        armstoel en voetenbanken. Daar staat de tv, waar u alle zenders op kunt ontvangen, een
                        dvd-speler met veel verschillende genres aan dvd’s en een radio.
                    </p>`,
                    images: ['woonkamer.jpg', 'eetkamer.jpg', 'trap.jpg'],
                    position: 'right'
                },
                {
                    name: 'Keuken',
                    content: `
                    <p>
                        Aan de rechterkant bevindt zich de open keuken, die van alle gemakken voorzien is. Er
                        bevinden zich een gasfornuis, magnetron, vaatwasser, koelkast met vriesvak. Ook een
                        koffiezetapparaat, Dolce Gusto, waterkoker, contact-grill zijn aanwezig. Het is een
                        goed uitgeruste keuken met servies, glazen, pannen, schalen etc, zodat u zelf een heerlijke
                        maaltijd kunt bereiden. Bij de keuken staat een eettafel met 4 eetkamerstoelen.
                    </p>`,
                    images: ['eetkamer.jpg', 'ontbijt.jpg', 'ingang.jpg'],
                    position: 'left'
                },
                {
                    name: 'Slaapkamer',
                    content: `
                    <p>
                        In dezelfde ruimte, maar gescheiden door een wand staat een Auping bed (180x200) met
                        een grote kast met hang -en leggedeeltes.
                    </p>
                    <p>
                        Op de zolder, bereikbaar door een vaste trap, staat het tweede Auping bed (180x200). Het is
                        een beleving om daar de nacht door te brengen in de sfeervolle ambiance. De zolder is goed
                        toegankelijk. Alleen niet toegankelijk voor mensen die minder mobiel zijn, want de zolder
                        heeft geen sta hoogte. De zolder is afsluitbaar door tentendoek.
                    </p>`,
                    images: ['bed.jpg', 'zolder-2.jpg', 'zolder.jpg'],
                    position: 'right'
                },
                {
                    name: 'Badkamer',
                    content: `
                    <p>
                        Aansluitend bevindt zich de badkamer met een wastafel, douchecabine en toilet. Er is een
                        haardroger aanwezig.
                    </p>`,
                    images: ['badkamer-sfeer.jpg', 'stoelen-decoratie.jpg', 'badkamer-2.jpg'],
                    position: 'left'
                },
                {
                    name: 'Kinderen',
                    content: `
                    <p>
                        Voor kinderen is er van alles te doen. In de woning is lego aanwezig voor alle
                        leeftijden, boeken, spelletjes, dvd’s. Verder kunnen we samen de beestjes eten geven, eitjes
                        rapen en de varkens aaien. Op aanvraag kunt u een kinderbed, kinderstoel en kinderservies
                        aanvragen.
                    </p>`,
                    images: ['varkens-knuffels.jpg', 'stoffen.jpg', 'paarden.jpg'],
                    position: 'right'
                },{
                    name: 'Binnenplaats',
                    content: `
                    <p>
                        De B&amp;B heeft aansluitend een afgesloten binnenplaats met een serre overkapping, waar u
                        heerlijk kunt zitten in het late avondzonnetje in het voorjaar of met een vuurkorf op een
                        koelere avond of een heerlijke BBQ in de zomer en zelfs een BBQ in de winter.
                        Dus kortom voor alle jaargetijden een heerlijke plek om te zijn. Er staat een ruime eettafel
                        met 4 stoelen, een BBQ, een vuurkorf en in de lente en zomer 2 heerlijke tuinstoelen om
                        lekker te relaxen.
                    </p>`,
                    images: ['druiven.jpg', 'serre.jpg', 'decoratie-serre.jpg'],
                    position: 'left'
                },
                {
                    name: 'Huisdieren',
                    content: `
                    <p>
                        In de B&amp;B zijn maximaal 2 huisdieren toegestaan. Op verzoek kan er een
                        hondenslaapmat, drink -en waterbakken aanwezig zijn. Op de binnenplaats kunnen ze vrij
                        rondlopen en in de directe omgeving kunt u prachtige wandelingen maken.
                    </p>`,
                    images: [['paard.jpg', 'bottom'], ['noa.jpg', 'top'], ['kippen.jpg', '10%']],
                    position: 'right'
                }
            ]
        }
    },
    components: {
        Page,
        SliderBlock
    },
    mounted() {
        var vm = this;
        /* eslint-disable */
        $('.slider').each(function(index){
            var slider = $(this);
            var sliderInitializer = slider.find('ul.slides');
            sliderInitializer.find('>li').addClass('slide');
            var childnum = sliderInitializer.find('li').length;
            
            var themeDefaults = {
                cellSelector: '.slide',
                cellAlign: 'left',
                wrapAround: true,
                pageDots: false,
                prevNextButtons: false,
                autoPlay: true,
                draggable: (childnum < 2 ? false: true),
                imagesLoaded: true,
                accessibility: true,
                rightToLeft: false,
                initialIndex: 0,
                freeScroll: false
            }; 

            // Attribute Overrides - options that are overridden by data attributes on the slider element
            var ao = {};
            ao.pageDots = (slider.attr('data-paging') === 'true' && sliderInitializer.find('li').length > 1) ? true : undefined;
            ao.prevNextButtons = slider.attr('data-arrows') === 'true'? true: undefined;
            ao.draggable = slider.attr('data-draggable') === 'false'? false : undefined;
            ao.autoPlay = slider.attr('data-autoplay') === 'false'? false: (slider.attr('data-timing') ? parseInt(slider.attr('data-timing'), 10): undefined);
            ao.accessibility = slider.attr('data-accessibility') === 'false'? false : undefined;
            ao.rightToLeft = slider.attr('data-rtl') === 'true'? true : undefined;
            ao.initialIndex = slider.attr('data-initial') ? parseInt(slider.attr('data-initial'), 10) : undefined;
            ao.freeScroll = slider.attr('data-freescroll') === "true" ? true: undefined;

            // Set data attribute to inidicate the number of children in the slider
            slider.attr('data-children',childnum);

            
            $(this).data('sliderOptions', jQuery.extend({}, themeDefaults, {}, ao));

            $(sliderInitializer).flickity($(this).data('sliderOptions'));

            $(sliderInitializer).on( 'scroll.flickity', function() {
              if(slider.find('.is-selected').hasClass('controls--dark')){
                slider.addClass('controls--dark');
              }else{
                slider.removeClass('controls--dark'); 
              }
            });

            slider.on("mousedown touchstart", () => {
                vm.tutorial = false;
            });
        });
        /* eslint-enable */
    }
}
</script>
